import { useTheme } from "@material-ui/core";
import TdcTheme from "@tdcerhverv/mui-theme";

export const ApplicationTheme = TdcTheme;

export function useApplicationTheme() {
  const theme = useTheme();

  return theme as typeof ApplicationTheme;
}
