import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { withSentry } from "@sentry/remix";
import { AnimatePresence } from "framer-motion";
import {
  Links,
  LinksFunction,
  LiveReload,
  Meta,
  MetaFunction,
  Scripts,
  ScrollRestoration,
  ShouldReloadFunction,
  useLoaderData,
  useOutlet,
} from "@remix-run/react";
import styles from "~/root.css";
import failureStyles from "~/styles/failure.css";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { ApplicationTheme } from "./Theme";

export const links: LinksFunction = () => {
  return [
    {
      rel: "preload",
      href: "https://s.c.dk/fonts/mukta-mahee/mukta-mahee-latin-300-normal.woff2",
      as: "font",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      href: "https://s.c.dk/fonts/mukta-mahee/mukta-mahee-latin-500-normal.woff2",
      as: "font",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      href: "https://s.c.dk/fonts/mukta-mahee/mukta-mahee-latin-600-normal.woff2",
      as: "font",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      href: "https://s.c.dk/fonts/TDC_bold.woff2",
      as: "font",
      crossOrigin: "anonymous",
    },

    { rel: "stylesheet", href: styles },
    { rel: "stylesheet", href: failureStyles },
  ];
};

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "Aktivering af SmartWear",
  viewport: "width=device-width,initial-scale=1",
});

export const loader = () => {
  return {
    env: {
      sentry_release: process.env.release_name_env,
      environment: process.env.ENVIRONMENT,
    },
  };
};

export const unstable_shouldReload: ShouldReloadFunction = () => false;

function Layout({ children, customScript }: any) {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <style id="jss-server-side"></style>
      </head>
      <body>
        <div className="container">
          <ThemeProvider theme={ApplicationTheme}>
            <CssBaseline />

            {children}
          </ThemeProvider>
        </div>

        <ScrollRestoration />
        {customScript}
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

function App() {
  const outlet = useOutlet();
  const data = useLoaderData();

  return (
    <Layout
      customScript={
        <script
          dangerouslySetInnerHTML={{
            __html: `window["configurations"] = ${JSON.stringify(
              data?.env || {}
            )}`,
          }}
        />
      }
    >
      <div className="logo-container">
        <img
          src="/logo.svg"
          className="main-logo__image"
          alt="Gå til TDC.dk"
          width={100}
          height={45}
        />
      </div>

      <AnimatePresence exitBeforeEnter initial={false}>
        {outlet}
      </AnimatePresence>
    </Layout>
  );
}

export default withSentry(App, {
  errorBoundaryOptions: {
    fallback: <ErrorBoundary />,
  },
});
